<!-- 服务建议详情 -->
<template>
  <div class="service_log_details">
    <div class="card">
      <div class="card_top">
        <div class="update_time">
          <div class="i_box">
            <i class="iconfont icon-jr-icon-alarm"></i>
          </div>
          <span>提交时间：</span>
          <span>{{ info.pushTime }}</span>
        </div>
        <div class="tittle">
          <div class="img_box">
            <!-- <img :src="enterpriseInfo.companyLogo" alt="" /> -->
            <img v-if="enterpriseInfo.companyLogo" :src="enterpriseInfo.companyLogo" alt="" />
            <img v-else src="@/assets/image/enterprise-default-logo.png" alt="" />
          </div>
          <div class="enterpriseName">{{ enterpriseInfo.companyFullName }}服务建议</div>
          <!-- <div class="tag_box" :class="getServiceTypeColor(info.serviceType)">
            {{ info.serviceType }}
          </div> -->
        </div>
      </div>
      <div class="card_bottom">
        <div class="label">
          <div class="border_box"></div>
          <span>服务图片</span>
        </div>
        <div class="img_box_list">
          <div class="pic_box" v-for="(item, index) in info.suggestionImageList" :key="index">
            <el-image
              style="width: 284px; height: 160px"
              :src="item"
              :preview-src-list="info.suggestionImageList"
              fit="contain "
            >
            </el-image>
          </div>
        </div>
        <div class="label">
          <div class="border_box"></div>
          <span>服务详情</span>
        </div>
        <p>
          {{ info.suggestionText }}
        </p>
      </div>
      <div class="card_footer">
        <el-button type="primary" @click="back">返回上一级</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { searchSuggestionById, listById } from '@/api/enterpriseCustomer.js'
export default {
  name: 'ServiceSuggestionDetails',
  components: {},
  watch: {
    companyId(val) {
      if (val) {
        this.listById(val)
      }
    }
  },
  data() {
    return {
      enterpriseInfo: {}, // 企业信息
      info: {}, // 服务建议信息
      companyId: null,
      id: null // 服务建议id
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.search()
    }
  },
  methods: {
    //  查询服务建议详情
    async search() {
      const res = await searchSuggestionById({ id: this.id })
      if (res.resultCode === 200) {
        this.info = res.data
        this.companyId = res.data.companyId
        // this.$message.success('获取服务建议详情成功!')
      } else {
        this.$message.warning('获取服务建议详情失败!')
      }
    },
    // 查询企业详情
    async listById(id) {
      const res = await listById({ id })
      if (res.resultCode === 200) {
        this.enterpriseInfo = res.data
      } else {
        this.$message.warning('获取服务建议详情失败!')
      }
    },
    // 返回
    back() {
      this.$router.push({
        name: 'EnterpriseDetails',
        query: {
          id: this.companyId,
          val: 5
        }
      })
    },
    // 计算服务类型标签颜色
    getServiceTypeColor(val) {
      if (val === '电话沟通') {
        return 'color2'
      } else if (val === '线上会议') {
        return 'color3'
      } else if (val === '企业拜访') {
        return 'color4'
      } else if (val === '活动见面') {
        return 'color5'
      } else {
        return 'color6'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.service_log_details {
  height: 100%;
}
.card {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;
  .card_top {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    .update_time {
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateX(-50%);
      .i_box {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        i {
          font-size: 16px;
          color: #252631;
        }
      }
      span {
        font-size: 16px;
        color: #252631;
      }
    }
    .tittle {
      display: flex;
      align-items: center;
      margin: 0 auto;
      .img_box {
        width: 60px;
        height: 60px;
        margin-right: 10px;
        img {
          width: 60px;
          height: 60px;
        }
      }
      .enterpriseName {
        font-weight: bold;
        font-size: 24px;
        line-height: 34px;
        margin-right: 10px;
      }
      // .tag_box {
      //   width: 76px;
      //   height: 34px;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   font-size: 14px;
      //   color: #fff;
      //   border-radius: 4px;
      // }
    }
  }
  .card_bottom {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    .img_box_list {
      width: 100%;
      height: 160px;
      display: flex;
      flex-flow: row wrap;
      padding: 20px;
      box-sizing: border-box;
      .pic_box {
        width: 284px;
        height: 160px;
        margin-right: 16px;
      }
    }
    p {
      font-size: 16px;
      color: rgba(37, 38, 49, 0.85);
      text-indent: 2em;
      margin-left: 20px;
    }
    .label {
      display: flex;
      align-items: center;
      margin-top: 30px;
      span {
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        color: #448aff;
      }
    }
    .border_box {
      width: 4px;
      height: 20px;
      margin-right: 10px;
      border: #448aff 2px solid;
      box-sizing: border-box;
    }
  }
  .card_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    margin-top: auto;
    padding: 20px;
    box-sizing: border-box;
  }
}

// .color2 {
//   background-color: #82d066;
// }
// .color3 {
//   background-color: #faad14;
// }
// .color4 {
//   background-color: #13c2c2;
// }
// .color5 {
//   background-color: #eb2f96;
// }
// .color6 {
//   background-color: #fadb14;
// }
</style>