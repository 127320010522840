import { render, staticRenderFns } from "./serviceSuggestionDetails.vue?vue&type=template&id=45a00c0d&scoped=true"
import script from "./serviceSuggestionDetails.vue?vue&type=script&lang=js"
export * from "./serviceSuggestionDetails.vue?vue&type=script&lang=js"
import style0 from "./serviceSuggestionDetails.vue?vue&type=style&index=0&id=45a00c0d&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45a00c0d",
  null
  
)

export default component.exports